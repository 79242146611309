import React from 'react';
import { ArrayField, Datagrid, TextField } from 'react-admin'

class TenantDeviceList extends React.Component {

    render() {
        console.log(this.props);
        return (
            <ArrayField {...this.props} source="lineItems">
                <Datagrid>
                    <TextField source="label"/>
                </Datagrid>
            </ArrayField>
        )
    }
}

export default TenantDeviceList;