import { Auth } from 'aws-amplify'

const authProvider = {
    logout: (params) => {
        console.log("Logging out")
        console.log(params);
        return Auth.signOut();
    },
    checkAuth: (params) => {
        return Promise.resolve();
    },
    getPermissions: (params) => {
        console.log("getPerm")
        console.log(params);
        return Promise.resolve();

    }
};

export default authProvider;