import React from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import LabelIcon from '@material-ui/icons/Label';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

import { useTranslate, DashboardMenuItem, MenuItemLink, getResources } from 'react-admin';

const InvoicixMenu = ({ onMenuClick, logout }) => {
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    const resources = useSelector(getResources);


    return (
        <div>
            <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
            <MenuItemLink
                to="/tenants"
                primaryText="Tenants"
                leftIcon={<AccountBoxIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                to="/generate-invoice"
                primaryText="New Invoice"
                leftIcon={<MonetizationOnIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
        </div>
    );
};

export default withRouter(InvoicixMenu);