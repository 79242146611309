import React from 'react';
import { List, Datagrid, TextField, FunctionField } from 'react-admin'
import TenantDeviceList from "./TenantDeviceList";


class TenantList extends React.Component {

    renderLocation = record => {
        return record.companyAddressLine1 + ", " + record.companyAddressCity + " " + record.companyAddressState;
    };

    renderLineItems = record => {
        return record.lineItems.length + " meters";
    }

    render() {
        return (
            <List {...this.props} title="Current Tenants">
                <Datagrid rowClick="expand" expand={<TenantDeviceList  />} >
                    <TextField source="companyName" />
                    <FunctionField label="Location" render={this.renderLocation}  />
                    <FunctionField label="# of Meters" render={this.renderLineItems} />
                </Datagrid>
            </List>
        )
    }
}

export default TenantList;