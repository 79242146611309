import React from 'react';
import './App.css';

import { Admin, Resource, ListGuesser } from 'react-admin';
import Amplify, { Auth } from 'aws-amplify';
import { withAuthenticator } from 'aws-amplify-react';

import dataProvider from "./dataProvider";
import authProvider from "./authProvider";

import {API_NAME, API_URL} from "./constants";
import Dashboard from "./components/Dashboard";
import TenantList from "./components/TenantList";
import customRoutes from "./customRoutes";
import InvoicixLayout from './components/InvoicixLayout';

Amplify.configure({
  Auth: {
    region: 'us-east-1',
    userPoolId: 'us-east-1_iQsoZqXlC',
    userPoolWebClientId: '2561qunq0bn5gvkapv9jr832bv',
    mandatorySignIn: true
  },
  API: {
    endpoints: [
      {
        name: API_NAME,
        endpoint: API_URL
      }
    ]
  }
});

function App() {
  return (
    <Admin customRoutes={customRoutes} layout={InvoicixLayout} dashboard={Dashboard} authProvider={authProvider} dataProvider={dataProvider} title="Invoicix">
      <Resource name="tenants" list={TenantList}/>
    </Admin>
  );
}

export default withAuthenticator(App);
