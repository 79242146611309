import React from 'react';

import { Layout } from 'react-admin'
import InvoicixNotification from "./InvoicixNotification";
import InvoicixAppBar from "./InvoicixAppBar";
import InvoicixMenu from "./InvoicixMenu";

class InvoicixLayout extends React.Component {
    render() {
        return (
            <Layout {...this.props} notification={InvoicixNotification} appBar={InvoicixAppBar} menu={InvoicixMenu}/>
        )
    }
}

export default InvoicixLayout;