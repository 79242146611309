import React from 'react';

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { Title } from 'react-admin';
import classes from "aws-amplify-react/lib-esm/Amplify-UI/Amplify-UI-Theme";

class Dashboard extends React.Component {
    render() {
        return (
            <Card className={classes.root}>
                <Title title="Invoicix Dashboard" />
                <CardContent classes={classes.root}>
                    <h2>Welcome to the Invoicix Dashboard</h2>
                    <h3>
                        How To Use
                    </h3>
                    <p>
                        Clicking on the items on the left side of the screen will allow you to navigate through the different
                        functions of the application. Below is a list of the available tools and what they can do.
                    </p>
                    <ol>
                        <li>
                            <h4>Tenants</h4>
                            <p>This is where you can view a listing of the tenants for your account, and the label
                            of the listed meters for each tenant. These are the meters that will show up when generating
                            an invoice. Note at this point in time, this page only serves as a reference.</p>
                        </li>
                        <li>
                            <h4>New Invoice</h4>
                            <p>This will permit you to generate an invoice for a tenant. Simply select the desired tenant,
                            choose the start and ending dates. Enter the energy charge for each meter, and clicking the
                            button will send an email with the invoice to the emails pre-configured automatically.</p>
                        </li>
                    </ol>
                </CardContent>
            </Card>
        );
    }
}

export default Dashboard;