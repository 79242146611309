import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import {Error, Loading, Title, useDataProvider} from 'react-admin';
import classes from "aws-amplify-react/lib-esm/Amplify-UI/Amplify-UI-Theme";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

import React, {useEffect, useRef, useState} from 'react';
import {Button} from "@material-ui/core";


const checkDates = (startDateValue, endDateValue) => {
    return startDateValue && endDateValue && startDateValue.length === 10 && endDateValue.length === 10;
};

const doGenerateInvoice = async(dataProvider, payload, callback) => {
    return await dataProvider.getOne('generate-invoice', payload)
        .then(res => {
            const message = res.data.success ? res.data.message : "An error occurred";
            return {error: !res.data.success, message, finalState: "COMPLETE"};
        });
};

const GenerateInvoice = (props) => {
    let startDateObj = new Date();
    startDateObj.setDate(1);
    startDateObj.setMonth(startDateObj.getMonth()-1);

    var endDateObj = new Date(startDateObj.getFullYear(), startDateObj.getMonth()+1, 0);


    const dataProvider = useDataProvider();
    const [generateInvoicePayload, setGenerateInvoicePayload] = useState(false);

    const [tenants, setTenants] = useState();
    const [selectedTenant, setSelectedTenant] = useState("unknown");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [steps, setSteps] = useState({step1: true, step2: false, step3: false, step4: false, step5: false});
    const [startDate, setStartDate] = useState(startDateObj.getFullYear() + "-" + (startDateObj.getMonth()+1) + "-01");

    const [endDate, setEndDate] = useState(endDateObj.getFullYear() + "-" + (endDateObj.getMonth()+1) + "-" + endDateObj.getDate());
    const [energyCharge, setEnergyCharge] = useState(0.0);
    const [generateInvoiceButtonText, setGenerateInvoiceButtonText] = useState("Generate Invoice");
    const [disableGenerateInvoiceButton, setDisableGenerateInvoiceButton] = useState(false);


    const [finalState, setFinalState] = useState(false)

    const startDateRef = useRef();
    const endDateRef = useRef();
    const submitButtonRef = useRef();

    useEffect(() => {
        dataProvider.getList('tenants')
            .then(({ data }) => {
                setTenants(data);

                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
    }, []);

    useEffect(() => {
        if(generateInvoicePayload) {
            console.log("Generating invoice")
            doGenerateInvoice(dataProvider, generateInvoicePayload)
                .then(response => {
                    if(response.error) {
                        setFinalState({error:  true, message: response.error, finalState: "ERROR"});
                    } else {
                        setFinalState(response);
                    }

                    setSteps({...steps, step5: true});
                });

        }
    }, [generateInvoicePayload]);

    if (loading) return <Loading />;
    if (error) return <Error />;
    if (!tenants) return null;;

    return (
        <Card>
            <Title title="Generate An Invoice" />
            <CardContent>
                <form className={classes.container} noValidate>
                    <Box display="block" m={3} hidden={!steps.step1}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="tenant-label">Tenant</InputLabel>
                            <Select
                                labelId="tenant-label"
                                id="tenant-select"
                                value={selectedTenant}
                                onChange={(event, child) => {
                                    setSelectedTenant(event.target.value)
                                    setSteps({...steps, step2: true, step3: checkDates(startDateRef.current.value, endDateRef.current.value)})
                                }}
                            >
                                <MenuItem key="unknown" value="unknown">Please Select A Tenant</MenuItem>
                                {tenants.map(obj => {
                                    return (
                                        <MenuItem key={obj.companyName} value={obj.companyName}>{obj.companyName}</MenuItem>
                                    );
                                })}
                            </Select>

                        </FormControl>
                    </Box>

                    <Box component="div" m={0} p={0} hidden={!steps.step2}>
                        <Box component="div" m={3} display="inline">
                            <TextField
                                id="date"
                                label="Invoice Start"
                                type="date"
                                value={startDate}
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputRef={startDateRef}
                                onChange={(evt) => {
                                    setStartDate(evt.target.value)
                                    setSteps({...steps, step3: checkDates(startDateRef.current.value, endDateRef.current.value)});
                                }}
                            />
                        </Box>
                        <Box component="div" m={3} display="inline">
                            <TextField
                                id="date"
                                label="Invoice End"
                                type="date"
                                value={endDate}
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputRef={endDateRef}
                                onChange={(evt) => {
                                    setEndDate(evt.target.value)
                                    setSteps({...steps, step3: checkDates(startDateRef.current.value, endDateRef.current.value)});
                                }}
                            />
                        </Box>
                    </Box>

                    <Box hidden={!steps.step3} m={3}>
                        <TextField
                            id="amount"
                            label="Energy Usage Charge"
                            type="number"
                            inputProps={{ min: "0", max: "100", step: "0.1" }}

                            value={energyCharge}
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true
                            }}
                            onChange={(e) => {
                                const value = parseFloat(e.target.value);
                                setEnergyCharge(value)
                                if(value > 0)
                                    setSteps({...steps, step4: true});
                            }}
                        />
                    </Box>

                    <Box hidden={!steps.step4} m={3}>
                        <Button
                            variant="contained"
                            color="primary"
                            buttonRef={submitButtonRef}
                            disabled={disableGenerateInvoiceButton}
                            startIcon={<AttachMoneyIcon/>}
                            onClick={(evt) => {
                                setGenerateInvoiceButtonText("Generating Invoice...")
                                setDisableGenerateInvoiceButton(true);

                                let tenantObj = {};
                                for(var t in tenants) {
                                    const currTenant = tenants[t];

                                    if(currTenant.companyName === selectedTenant)
                                        tenantObj = currTenant;
                                }

                                const payload = {
                                    startDate, endDate, energyCharge, tenant: tenantObj, httpMethod: 'POST'
                                };

                                setGenerateInvoicePayload(payload);

                            }}>
                            {generateInvoiceButtonText}
                        </Button>

                        <div style={{color: finalState.error ? 'red' : 'blue', marginTop: 25}}>
                            {finalState.message}
                        </div>

                    </Box>

                    <Box hidden={!steps.step5} m={3}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={(evt) => {
                                console.log("Resetting...")
                                setGenerateInvoicePayload(false);
                                setSteps({step1: true, step2: false, step3: false, step4: false, step5: false})
                                setFinalState(false)
                                setSelectedTenant("unknown")

                                setGenerateInvoiceButtonText("Generate Invoice");
                                setDisableGenerateInvoiceButton(false);
                            }} >
                            Generate Another Invoice
                        </Button>
                    </Box>
                </form>
            </CardContent>
        </Card>

    );

};

const useStateWithPromise = defaultVal => {
    let [state, setState] = useState({
        value: defaultVal,
        resolve: () => {}
    });

    useEffect(
        () => {
            state.resolve(state.value);
        },
        [state]
    );

    return [
        state.value,
        updater => {
            return new Promise(resolve => {
                setState(prevState => {
                    let nextVal = updater;
                    if (typeof updater === "function") {
                        nextVal = updater(prevState.value);
                    }
                    return {
                        value: nextVal,
                        resolve
                    };
                });
            });
        }
    ];
};

export default GenerateInvoice;