import { API, Auth } from 'aws-amplify'
import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';
import { API_URL, API_NAME } from "./constants";

const apiUrl = API_URL;
const httpClient = fetchUtils.fetchJson;

async function getHeaders() {
    return {
        headers: { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
    };

}

export default {
    getList: (resource, params) => {
        console.log("getList: "+resource);

        return Auth.currentUserInfo()
            .then(userInfo => {
                return getHeaders().then(headers => {
                    return {
                        username: userInfo.username,
                        email: userInfo.attributes.email,
                        sub: userInfo.attributes.sub,
                        headers: { ...headers.headers, "X-Invoicix-Email": userInfo.attributes.email }
                    }
                })
            })
            .then(allInfo => {
                return API.get(API_NAME, "/config", allInfo)
                    .then(response => {
                        if(response.billingProfile) {
                            const billingProfile = response.billingProfile.map(profile => ({...profile, id: profile.companyName}));
                            return { data: billingProfile, total: response.billingProfile.length };
                        } else
                            throw "No profile found"
                    });

            }).catch(err => {
                console.log(err)
            });


        /*
        return httpClient(url).then(({ headers, json }) => ({
            data: json,
            total: parseInt(headers.get('content-range').split('/').pop(), 10),
        }));*/
    },

    getOne: (resource, params) => {
        console.log("getOne: " + resource);

        return Auth.currentUserInfo()
            .then(userInfo => {
                return getHeaders().then(headers => {
                    return {
                        username: userInfo.username,
                        email: userInfo.attributes.email,
                        sub: userInfo.attributes.sub,
                        headers: { ...headers.headers, "X-Invoicix-Email": userInfo.attributes.email },
                        body: params
                    }
                })
            })
            .then(allInfo => {
                if(params.httpMethod === 'POST') {
                    return API.post(API_NAME, "/" + resource, allInfo)
                        .then(response => {
                            return response;
                        });
                } else {
                    return API.get(API_NAME, "/" + resource, allInfo)
                        .then(response => {
                            return response;
                        });
                }


            }).catch(err => {
                console.log(err)
            });
    },
    getMany: (resource, params) => {
        console.log("getMany: "+resource);
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url).then(({ json }) => ({ data: json }));
    },

    getManyReference: (resource, params) => {
        console.log("getManyRef: "+resource);
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json,
            total: parseInt(headers.get('content-range').split('/').pop(), 10),
        }));
    },

    update: (resource, params) => {
        console.log("update: "+resource);
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({json}) => ({data: json}))
    },

    updateMany: (resource, params) => {
        console.log("updateMany: "+resource);
        const query = {
            filter: JSON.stringify({ id: params.ids}),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },

    create: (resource, params) => {
        console.log("create: "+resource);
        httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({json}) => ({
            data: {...params.data, id: json.id},
        }))
    },

    delete: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json })),

    deleteMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids}),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'DELETE',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    }
};